var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bgFFF" },
    [
      _c("div", { staticClass: "pageTitle" }, [_vm._v("订单详情")]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "120px",
            model: _vm.formInline,
            rules: _vm.rules,
          },
        },
        [
          _c("div", { staticClass: "section-title" }, [_vm._v("订单信息")]),
          _c("el-form-item", { attrs: { label: "订单状态:" } }, [
            _vm._v(_vm._s(_vm.formInline.stateDesc)),
          ]),
          _c("el-form-item", { attrs: { label: "支付时间:" } }, [
            _vm._v(
              _vm._s(_vm._f("timeFilter")(_vm.formInline.payTime, _vm.that))
            ),
          ]),
          !_vm.$route.query.isEdit
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车牌号码:" } },
                    _vm._l(_vm.formInline.plateList, function (item, index) {
                      return _c(
                        "span",
                        { key: index },
                        [
                          _c("el-tag", [
                            _vm._v(
                              _vm._s(item.plateColor) +
                                " : " +
                                _vm._s(item.plateNumber)
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.$route.query.isEdit
            ? _c(
                "div",
                _vm._l(_vm.formInline.plateList, function (val, ind) {
                  return _c(
                    "el-form-item",
                    { key: ind, attrs: { label: "车牌号码:", required: true } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "100px",
                            display: "inline-block",
                          },
                          attrs: {
                            prop: "plateList." + ind + ".numberName",
                            rules: [
                              {
                                required: true,
                                message: "请选择车牌号码",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: val.numberName,
                                callback: function ($$v) {
                                  _vm.$set(val, "numberName", $$v)
                                },
                                expression: "val.numberName",
                              },
                            },
                            _vm._l(
                              _vm.platerNumberLists,
                              function (item, platInd) {
                                return _c("el-option", {
                                  key: platInd,
                                  attrs: {
                                    disabled: item.disabled && item.disabled,
                                    label: item.numberName,
                                    value: item.numberName,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "210px",
                            padding: "0 20px 0 5px",
                            display: "inline-block",
                          },
                          attrs: {
                            prop: "plateList." + ind + ".plateNumber",
                            rules: [
                              {
                                required: true,
                                message: "请输入车牌号码",
                                trigger: "change",
                              },
                              {
                                required: true,
                                validator: _vm.checkNumber,
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: 7,
                              placeholder: "请输入车牌号",
                            },
                            model: {
                              value: val.plateNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  val,
                                  "plateNumber",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "val.plateNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" 车牌颜色 "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "100px",
                            display: "inline-block",
                            "margin-right": "10px",
                          },
                          attrs: {
                            prop: "plateList." + ind + ".plateColor",
                            rules: [
                              {
                                required: true,
                                message: "请选择车牌颜色",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: val.plateColor,
                                callback: function ($$v) {
                                  _vm.$set(
                                    val,
                                    "plateColor",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "val.plateColor",
                              },
                            },
                            _vm._l(_vm.colorList, function (v) {
                              return _c("el-option", {
                                key: v.code,
                                attrs: { label: v.desc, value: v.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      ind == 0
                        ? _c(
                            "el-button",
                            {
                              staticClass: "button-style",
                              attrs: { type: "primary", plain: "" },
                              on: { click: _vm.addNumber },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus icon-style",
                              }),
                            ]
                          )
                        : _c(
                            "el-button",
                            {
                              staticClass: "button-style",
                              attrs: { type: "warning", plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteNumber(ind)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-minus icon-style",
                              }),
                            ]
                          ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.formInline.phoneNumber
            ? _c("el-form-item", { attrs: { label: "手机号:" } }, [
                _vm._v(_vm._s(_vm.formInline.phoneNumber)),
              ])
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "购买渠道:" } }, [
                    _vm._v(_vm._s(_vm.formInline.buyTypeDesc)),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "实付金额:" } }, [
                    _vm._v(
                      _vm._s(_vm._f("priceFilter")(_vm.formInline.actualAmount))
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "支付渠道:" } }, [
                    _vm._v(_vm._s(_vm.formInline.channelTypeDesc)),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "支付设备:" } }, [
                    _vm._v(_vm._s(_vm.formInline.devTypeDesc)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("div", { staticClass: "section-title" }, [_vm._v("商品信息")]),
              _c("el-form-item", { attrs: { label: "商品名称:" } }, [
                _vm._v(_vm._s(_vm.formInline.parkCardTitle)),
              ]),
              _c("el-form-item", { attrs: { label: "商户名称:" } }, [
                _vm._v(_vm._s(_vm.formInline.operatorName)),
              ]),
              _c("el-form-item", { attrs: { label: "适用范围:" } }, [
                _vm._v(_vm._s(_vm.formInline.useRangeDesc)),
              ]),
              _c("el-form-item", { attrs: { label: "生效开始日期:" } }, [
                _vm._v(
                  _vm._s(
                    _vm._f("timeFilter")(_vm.formInline.startDate, _vm.that)
                  )
                ),
              ]),
              _vm.formInline.validityPeriod
                ? _c("el-form-item", { attrs: { label: "有效天数:" } }, [
                    _vm._v(_vm._s(_vm.formInline.validityPeriod)),
                  ])
                : _vm._e(),
              _c("el-form-item", { attrs: { label: "生效结束日期:" } }, [
                _vm._v(
                  _vm._s(_vm._f("timeFilter")(_vm.formInline.endDate, _vm.that))
                ),
              ]),
              _vm.formInline.carMaxNum
                ? _c("el-form-item", { attrs: { label: "车位限制:" } }, [
                    _vm._v(_vm._s(_vm.formInline.carMaxNum) + "辆"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("div", { staticClass: "section-title" }, [
                _vm._v("车牌绑定信息"),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "header-cell-class-name": "header-call-style",
                    border: "",
                    data: _vm.tableData,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: _vm.$t("list.index"),
                      width: "100",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      type: "parkCardHistory",
                      label: _vm.$t("searchModule.plate_number"),
                      align: "center",
                      "show-overflow-tooltip": "",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.plateColor) +
                                  " : " +
                                  _vm._s(scope.row.plateNumberNew)
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._l(_vm.tableCols, function (item) {
                    return _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.width,
                        formatter: item.formatter,
                        align: "center",
                      },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "margin-top": "10px" } },
        [
          _vm.$route.query.isEdit
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.history.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }